import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import { useGraphqlForm } from 'common/hooks/useGraphqlForm';
import { useReportBugMutation } from 'common/mutations/reportBug';
import { useEffect } from 'react';
import { FC, useCallback } from 'react';

type Inputs = {
  title: string;
  description: string;
};

const defaultValues: Inputs = {
  title: '',
  description: ''
};

interface BugReportDialogProps {
  open: boolean;
  onClose: () => void;
}

export const BugReportDialog: FC<BugReportDialogProps> = ({
  open,
  onClose
}) => {
  const [report, { data, error, loading }] = useReportBugMutation({
    onCompleted: ({ reportBug }) => {
      if (!reportBug.status) {
        return;
      }
      handleClose();
    }
  });
  const { register, handleSubmit, errors, reset } = useGraphqlForm<Inputs>(
    error,
    { defaultValues }
  );

  const onSubmit = useCallback(
    async ({ title, description }: Inputs) => {
      if (loading) {
        return;
      }

      await report({
        variables: {
          title,
          description
        }
      });
    },
    [loading, reset]
  );

  const handleClose = useCallback(() => {
    reset(defaultValues);

    if (onClose) {
      onClose();
    }
  }, [reset, onClose]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'xs'}>
      <DialogTitle>Report a Problem</DialogTitle>
      <DialogContent>
        <DialogContentText>
          We are sorry that you have encountered an issue. To help us fix it as
          quickly as possible please fill out the form below in as much detail
          as you can.
        </DialogContentText>
        <TextField
          fullWidth={true}
          label={'Title'}
          {...register('title', { required: 'Title is required.' })}
          helperText={errors?.title?.message}
          error={!!errors?.title}
        />
        <Box
          display={'flex'}
          justifyContent={'center'}
          marginTop={2}
          marginBottom={2}
        >
          <TextField
            fullWidth={true}
            rows={5}
            multiline
            placeholder={'A summary of the issue you encountered'}
            label={'Description'}
            {...register('description', {
              required: 'Description is required.'
            })}
            helperText={errors?.description?.message}
            error={!!errors.description}
          />
        </Box>
        <Box
          display={'flex'}
          justifyContent={'center'}
          marginTop={2}
          marginBottom={2}
        >
          <Box marginRight={1}>
            <Button variant={'outlined'} onClick={handleClose}>
              Cancel
            </Button>
          </Box>
          <Button
            fullWidth={true}
            variant={'contained'}
            color={'primary'}
            onClick={handleSubmit(onSubmit)}
          >
            {loading ? <CircularProgress /> : 'Submit'}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
