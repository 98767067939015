import * as Types from '../types/graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportBugMutationVariables = Types.Exact<{
  title: Types.Scalars['String'];
  description: Types.Scalars['String'];
}>;


export type ReportBugMutation = { __typename?: 'Mutation', reportBug: { __typename?: 'BugBoolean', status: boolean } };


export const ReportBugDocument = gql`
    mutation ReportBug($title: String!, $description: String!) {
  reportBug(input: {title: $title, description: $description}) {
    status
  }
}
    `;
export type ReportBugMutationFn = Apollo.MutationFunction<ReportBugMutation, ReportBugMutationVariables>;

/**
 * __useReportBugMutation__
 *
 * To run a mutation, you first call `useReportBugMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportBugMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportBugMutation, { data, loading, error }] = useReportBugMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useReportBugMutation(baseOptions?: Apollo.MutationHookOptions<ReportBugMutation, ReportBugMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportBugMutation, ReportBugMutationVariables>(ReportBugDocument, options);
      }
export type ReportBugMutationHookResult = ReturnType<typeof useReportBugMutation>;
export type ReportBugMutationResult = Apollo.MutationResult<ReportBugMutation>;
export type ReportBugMutationOptions = Apollo.BaseMutationOptions<ReportBugMutation, ReportBugMutationVariables>;