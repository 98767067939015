import { ApolloError } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, FieldValues, UseFormProps, UseFormRegister, Path } from 'react-hook-form';


const validateNotWhitespaceOnly = (message: string) => <T>(value: T): true | string => typeof value === 'string'
  ? value.trim().length > 0 || message
  : true

export function useGraphqlForm<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object
>(error?: ApolloError, useFormOptions?: UseFormProps<TFieldValues, TContext>) {
  interface GQLError { property: Path<TFieldValues>; message: string }
  const { setError, formState, register, control, ...rest } = useForm(useFormOptions);
  const { errors } = formState;

  const wrappedRegister: UseFormRegister<TFieldValues> = useCallback(
    (name, options) => {
      if (!options || !options.required) return register(name, options)

      const originalValidationRules =
        typeof options.validate === 'function'
          ? { default: options.validate }
          : options.validate

      const wrappedOptions = {
        ...options,
        validate: {
          ...originalValidationRules,
          whitespaces: validateNotWhitespaceOnly(typeof options.required === 'string' ? options.required : `${name} is required`)
        }
      }
      return register(name, wrappedOptions)
    },
    [register]
  )

  const wrappedControl = useMemo(
    () => ({
      ...control,
      register: wrappedRegister
    }),
    [control, wrappedRegister]
  )


  useEffect(() => {
    for (const graphQLError of error?.graphQLErrors || []) {
      for (const { property, message } of (graphQLError?.extensions?.errors ||
        []) as GQLError[]) {
        setError(property, { message, type: 'manual' });
      }
    }
  }, [error]);
  return { setError, errors, formState, register: wrappedRegister, control: wrappedControl, ...rest };
}
