import { useTheme } from '@mui/material';
import { FC } from 'react';

interface LogoProps {
  fill?: string;
  width?: number;
}

export const YoutubeLogo: FC<LogoProps> = (props) => {
  const theme = useTheme();
  const { fill = theme.palette.text.secondary, width = 32 } = props;

  return (
    <svg width={width} viewBox='0 0 34 34' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M30.3029 5.84141C31.7592 6.23362 32.9075 7.38172 33.2995 8.83824C34.0279 11.4986 33.9999 17.044 33.9999 17.044C33.9999 17.044 33.9999 22.5612 33.2997 25.2218C32.9075 26.6781 31.7594 27.8265 30.3029 28.2184C27.6423 28.9188 16.9999 28.9188 16.9999 28.9188C16.9999 28.9188 6.38535 28.9188 3.69695 28.1907C2.24042 27.7984 1.09233 26.6501 0.700116 25.1938C0 22.5612 0 17.016 0 17.016C0 17.016 0 11.4986 0.700116 8.83824C1.09207 7.38198 2.26844 6.20561 3.69669 5.81366C6.35733 5.11328 16.9997 5.11328 16.9997 5.11328C16.9997 5.11328 27.6423 5.11328 30.3029 5.84141ZM22.4611 17.0162L13.6111 22.1134V11.919L22.4611 17.0162Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='34' height='34' fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export const TwitterLogo: FC<LogoProps> = (props) => {
  const theme = useTheme();
  const { fill = theme.palette.text.secondary, width = 32 } = props;

  return (
    <svg
      width={width}
      viewBox='0 0 32 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M31.0807 2.96553C29.9342 3.46845 28.7125 3.80181 27.4388 3.96367C28.7491 3.18134 29.7492 1.95197 30.2194 0.470168C28.9977 1.19854 27.6489 1.71303 26.2114 2.00014C25.0514 0.764987 23.3981 0 21.5945 0C18.0952 0 15.278 2.84028 15.278 6.32222C15.278 6.82322 15.3204 7.30495 15.4245 7.76356C10.1698 7.50728 5.52012 4.98879 2.39659 1.1523C1.85127 2.09842 1.5314 3.18134 1.5314 4.34713C1.5314 6.53611 2.65865 8.47652 4.33892 9.59991C3.32344 9.58064 2.32722 9.28582 1.48323 8.82144C1.48323 8.84071 1.48323 8.86576 1.48323 8.89081C1.48323 11.9623 3.67414 14.5136 6.54717 15.1013C6.03268 15.2419 5.47195 15.3094 4.89002 15.3094C4.48537 15.3094 4.07686 15.2863 3.6934 15.2015C4.51235 17.7045 6.83621 19.5447 9.59941 19.6045C7.44897 21.2867 4.71853 22.3002 1.76263 22.3002C1.24429 22.3002 0.747145 22.2771 0.25 22.2135C3.04981 24.019 6.36797 25.05 9.94626 25.05C21.5771 25.05 27.936 15.4154 27.936 7.06409C27.936 6.78468 27.9263 6.51491 27.9129 6.24707C29.1673 5.35684 30.2213 4.245 31.0807 2.96553Z'
        fill={fill}
      />
    </svg>
  );
};

export const SpotifyLogo: FC<LogoProps> = (props) => {
  const theme = useTheme();
  const { fill = theme.palette.text.secondary, width = 32 } = props;

  return (
    <svg
      width={width}
      viewBox='0 0 27 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.5 28C20.952 28 27 21.728 27 14C27 6.272 20.952 0 13.5 0C6.048 0 0 6.272 0 14C0 21.728 6.048 28 13.5 28ZM18.981 20.5987V20.5998C18.0731 20.5998 15.2055 17.3005 7.146 19.0132C6.93338 19.0703 6.6555 19.1602 6.498 19.1602C5.46863 19.1602 5.27175 17.563 6.37875 17.3192C10.8371 16.2983 15.3934 16.3882 19.2791 18.7985C20.2061 19.4122 19.8124 20.5987 18.981 20.5987ZM20.4469 16.8968C20.3197 16.8618 20.3569 16.9773 19.7752 16.66C16.3721 14.5717 11.3006 13.7293 6.78713 14.9998C6.52612 15.0733 6.38437 15.1468 6.13912 15.1468C4.93425 15.1468 4.61475 13.2673 5.92763 12.8835C11.2331 11.3377 16.9245 12.2395 20.8867 14.6837C21.3277 14.9543 21.5021 15.3055 21.5021 15.7955C21.4965 16.4045 21.0397 16.8968 20.4469 16.8968ZM5.1165 8.16433C10.2049 6.61967 17.9055 7.10733 22.6935 10.0053C23.9209 10.7392 23.4382 12.5953 22.1332 12.5953L22.1321 12.5942C21.8486 12.5942 21.6742 12.5207 21.429 12.3737C17.5556 9.975 10.6245 9.39983 6.14025 10.6983C5.94338 10.7543 5.69813 10.8442 5.43713 10.8442C4.71825 10.8442 4.16925 10.262 4.16925 9.51183C4.16925 8.74533 4.62712 8.31133 5.1165 8.16433Z'
        fill={fill}
      />
    </svg>
  );
};

export const InstagramLogo: FC<LogoProps> = (props) => {
  const theme = useTheme();
  const { fill = theme.palette.text.secondary, width = 32 } = props;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      viewBox='-100.7682 -167.947 873.3244 1007.682'
    >
      <g fill={fill}>
        <path d='M335.895 0c-91.224 0-102.663.387-138.49 2.021-35.752 1.631-60.169 7.31-81.535 15.612-22.088 8.584-40.82 20.07-59.493 38.743-18.674 18.673-30.16 37.407-38.743 59.495C9.33 137.236 3.653 161.653 2.02 197.405.386 233.232 0 244.671 0 335.895c0 91.222.386 102.661 2.02 138.488 1.633 35.752 7.31 60.169 15.614 81.534 8.584 22.088 20.07 40.82 38.743 59.495 18.674 18.673 37.405 30.159 59.493 38.743 21.366 8.302 45.783 13.98 81.535 15.612 35.827 1.634 47.266 2.021 138.49 2.021 91.222 0 102.661-.387 138.488-2.021 35.752-1.631 60.169-7.31 81.534-15.612 22.088-8.584 40.82-20.07 59.495-38.743 18.673-18.675 30.159-37.407 38.743-59.495 8.302-21.365 13.981-45.782 15.612-81.534 1.634-35.827 2.021-47.266 2.021-138.488 0-91.224-.387-102.663-2.021-138.49-1.631-35.752-7.31-60.169-15.612-81.534-8.584-22.088-20.07-40.822-38.743-59.495-18.675-18.673-37.407-30.159-59.495-38.743-21.365-8.302-45.782-13.981-81.534-15.612C438.556.387 427.117 0 335.895 0zm0 60.521c89.686 0 100.31.343 135.729 1.959 32.75 1.493 50.535 6.965 62.37 11.565 15.68 6.094 26.869 13.372 38.622 25.126 11.755 11.754 19.033 22.944 25.127 38.622 4.6 11.836 10.072 29.622 11.565 62.371 1.616 35.419 1.959 46.043 1.959 135.73 0 89.687-.343 100.311-1.959 135.73-1.493 32.75-6.965 50.535-11.565 62.37-6.094 15.68-13.372 26.869-25.127 38.622-11.753 11.755-22.943 19.033-38.621 25.127-11.836 4.6-29.622 10.072-62.371 11.565-35.413 1.616-46.036 1.959-135.73 1.959-89.694 0-100.315-.343-135.73-1.96-32.75-1.492-50.535-6.964-62.37-11.564-15.68-6.094-26.869-13.372-38.622-25.127-11.754-11.753-19.033-22.943-25.127-38.621-4.6-11.836-10.071-29.622-11.565-62.371-1.616-35.419-1.959-46.043-1.959-135.73 0-89.687.343-100.311 1.959-135.73 1.494-32.75 6.965-50.535 11.565-62.37 6.094-15.68 13.373-26.869 25.126-38.622 11.754-11.755 22.944-19.033 38.622-25.127 11.836-4.6 29.622-10.072 62.371-11.565 35.419-1.616 46.043-1.959 135.73-1.959' />
        <path d='M335.895 447.859c-61.838 0-111.966-50.128-111.966-111.964 0-61.838 50.128-111.966 111.966-111.966 61.836 0 111.964 50.128 111.964 111.966 0 61.836-50.128 111.964-111.964 111.964zm0-284.451c-95.263 0-172.487 77.224-172.487 172.487 0 95.261 77.224 172.485 172.487 172.485 95.261 0 172.485-77.224 172.485-172.485 0-95.263-77.224-172.487-172.485-172.487m219.608-6.815c0 22.262-18.047 40.307-40.308 40.307-22.26 0-40.307-18.045-40.307-40.307 0-22.261 18.047-40.308 40.307-40.308 22.261 0 40.308 18.047 40.308 40.308' />
      </g>
    </svg>
  );
};

export const FacebookLogo: FC<LogoProps> = (props) => {
  const theme = useTheme();
  const { fill = theme.palette.text.secondary, width = 32 } = props;

  return (
    <svg
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width={width}
    >
      <path d='M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z' />
    </svg>
  );
};
